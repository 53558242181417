import BackDrop from 'components/BackDrop';
import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { getUserInfo } from 'requests/global';
// import sleep from 'utils/sleep';
import { useNavigate, useLocation } from 'react-router-dom';
import StorageService from 'config/StorageService';
import { SESSION_USER } from 'config/session';
import AuthenticationService from 'config/AuthenticationService';
import { IUser } from 'interfaces/User';
import { ISede } from 'interfaces/Company';

export interface IContext {
  userInfo: IUser;
  setUserInfo: (userInfo: IUser) => void;
  company: ISede;
  setCompany: Function;
  loading: boolean;
  isSuperAdmin: boolean;
}

export const NapNegocios = createContext<IContext>({} as IContext);

/**
 * Hook para obtener la información del usuario y su sede actual
 * @return userInfo - Información del usuario
 * @return company - Sede actual
 * @return loading - Estado de carga
 * @return setCompany - Función para cambiar la sede actual
 * @return setUserInfo - Función para cambiar la información del usuario
 */
export const useNapNegocios = (): IContext => useContext(NapNegocios);

const rolesToSales = ['vendedor', 'admin', 'caja', 'cajaSimple', 'coordinador'];

const freeRoutes = ['/home', '/login', '/forgotpassword']; // Rutas que se puede acceder sin estar logeado.

const NapNegociosProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<IUser>({} as IUser);
  const [company, setCompany] = useState<ISede>({} as ISede);

  useEffect(() => {
    const findRoute = freeRoutes.find((e) => location.pathname.includes(e));

    if (!findRoute && !StorageService.get(SESSION_USER)) {
      navigate('/login');
      setLoading(false);
      return;
    }

    if (!findRoute && StorageService.get(SESSION_USER)) {
      getData();
      return;
    }

    setLoading(false);
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await getUserInfo();

      if (!res.message) {
        await AuthenticationService.logout();
        navigate('/login');

        return;
      }

      if (!res) return navigate('/login');

      setCompany(res.data.campus);
      setUserInfo(res.data);

      if (rolesToSales.includes(res?.data?.roles[0])) navigate('/sales');
      else if (res?.data?.roles?.includes('almacen')) navigate('/my-products');
    } catch (error) {
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  const isSuperAdmin = userInfo?.roles?.includes('superadmin');

  return (
    <NapNegocios.Provider value={{ userInfo, company, setCompany, loading, setUserInfo, isSuperAdmin }}>
      {children}
      <BackDrop loading={loading} />
    </NapNegocios.Provider>
  );
};
export default NapNegociosProvider;
