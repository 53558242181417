import StorageService from './StorageService';
import { SESSION_USER } from 'config/session';

class UserService {
  static all = StorageService.get(SESSION_USER);

  static token() {
    return this.all ? this.all.token : null;
  }

  static user() {
    return this.all ? this.all.user : null;
  }

  static company() {
    return this.all ? this.all.company : null;
  }

  static campus() {
    return this.all ? this.all.campus : null;
  }
}

export default UserService;
