import { notification } from 'antd';
import { useNapNegocios } from 'context';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { getNotifications } from 'requests/notifications';

interface IContext {
  notifications: { historic: any[]; noviews: any[] };
  setNotifications: Function;
  isLoading: boolean;
  setIsLoading: Function;
  obtainNotifications: Function;
}

export const NotificationContext = createContext({} as IContext);
export const useNotification = () => useContext(NotificationContext);

const NotificationProvider = ({ children }: { children: ReactNode }) => {
  const { userInfo } = useNapNegocios();

  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState({ historic: [], noviews: [] } as {
    historic: any[];
    noviews: any[];
  });

  useEffect(() => {
    const interval = setInterval(() => {
      obtainNotifications();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [notifications]);

  useEffect(() => {
    obtainNotifications();
  }, [userInfo]);

  const obtainNotifications = useCallback(async () => {
    if (!userInfo._id) return;

    setIsLoading(true);
    try {
      const res = await getNotifications(userInfo._id);

      if (notifications.noviews.length > 0 && res.noviews.length > notifications.noviews.length) {
        const difference = res.noviews.length - notifications.noviews.length;

        notification.info({
          message: `Tienes ${difference} notificaciones nuevas.`,
          duration: 0,
        });
      }

      setNotifications(res);
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [notifications, userInfo]);

  const value = {
    notifications,
    setNotifications,
    isLoading,
    setIsLoading,
    obtainNotifications,
  };

  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
};

export default NotificationProvider;
