import { createRoot } from 'react-dom/client';

// CSS
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'antd/dist/antd.min.css';
import 'assets/styles/spinner.css';
import 'assets/styles/custom.css';

import App from './App';
import { HelmetProvider } from 'react-helmet-async';

document.addEventListener('wheel', function (event) {
  const numberInput: any = document.activeElement;

  if (numberInput && numberInput.type === 'number') numberInput.blur();
});

createRoot(document.getElementById('root')!).render(
  <HelmetProvider>
    <App />
  </HelmetProvider>
);
