import axios from 'axios';
import { API_SUNAT } from 'config/api.config';
import UserService from 'config/UserService';
import { IFacturationInfo } from 'interfaces/FactInfo';
import { IOrder } from 'interfaces/Order';
import { ISerie } from 'interfaces/Serie';

export const postBillingCompany = async (data: IFacturationInfo) => {
  const res = await axios.post(`${API_SUNAT}/fact/register/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getBillingCompany = async () => {
  const res = await axios.get(`${API_SUNAT}/fact/information/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getSerie = async () => {
  const res = await axios.get(`${API_SUNAT}/serie/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const postSerie = async (data: ISerie) => {
  const res = await axios.post(`${API_SUNAT}/serie/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putSerie = async (idSerie: string, data: ISerie) => {
  const res = await axios.put(`${API_SUNAT}/serie/${idSerie}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getComprobants = async (type: string, query = '') => {
  const res = await axios.get(`${API_SUNAT}/order/facturation-pay/${type}/${UserService.campus()}${query}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getPendingComprobants = async (type: string) => {
  const res = await axios.get(`${API_SUNAT}/order/facturation/${type}/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getCreditNotes = async () => {
  const res = await axios.get(`${API_SUNAT}/creditnote/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// SEND TO SUNAT
export const sentIndividual = async (idComprobant: string, data: any) => {
  const res = await axios.post(`${API_SUNAT}/fact/sendUnit/${idComprobant}/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

interface IPostMassiveBallots {
  ids: string[];
}
export const postMassiveBallots = async (data: IPostMassiveBallots) => {
  const res = await axios.post(`${API_SUNAT}/fact/sendMassive/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

interface IGenerateCreditNote {
  tipoDoc: string;
  numDoc: string;
  rznSocial: string;
  address: string;
}
export const generateCreditNote = async (id: string, data: IGenerateCreditNote) => {
  const res = await axios.post(`${API_SUNAT}/fact/generate-credit-note/${id}/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getPendingBilling = async (campus?: string) => {
  if (UserService.campus()) {
    const res = await axios.get(`${API_SUNAT}/order/pending-fact/${campus ?? UserService.campus()}`, {
      headers: { Authorization: UserService.token() },
    });
    return res.data;
  } else return 0;
};

interface sendMailInformationInt {
  email: string;
  data: IOrder;
  tipoDoc: string;
  url: string;
}
export const sendEmailVoucher = async (data: sendMailInformationInt) => {
  const res = await axios.post(`${API_SUNAT}/fact/send-email/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const sendGuiaRemision = async (id: string, data: { serie: string }) => {
  const res = await axios.post(`${API_SUNAT}/fact/referral-guide/${id}/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// ENVÍO MASIVO

export const getMassiveComprobants = async () => {
  const res = await axios.get(`${API_SUNAT}/massive/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });

  return res.data;
};

// VOLVER A ENVIAR EL MASIVO
export const resendMassiveToSunat = async (id: string) => {
  const res = await axios.get(`${API_SUNAT}/massive/resend/${id}/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// REVISAR ESTADO DEL COMPROBANTE
export const checkStatusComprobants = async (id: string) => {
  const res = await axios.get(`${API_SUNAT}/massive/status-ticket/${id}/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// REVISAR ESTADO DEL COMPROBANTE
export const acceptMassive = async (id: string) => {
  const res = await axios.get(`${API_SUNAT}/massive/accept/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// ELIMINAR EL ENVÍO MASIVO
export const deleteMassive = async (id: string) => {
  const res = await axios.delete(`${API_SUNAT}/massive/${id}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

// VALIDAR FECHAS DEL ENVÍO MASIVO
export const verifyDatesOfMassive = async (id: string) => {
  const res = await axios.get(`${API_SUNAT}/massive/verify-dates/${id}/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });

  return res.data;
};
