interface IProps {
  color?: string;
}

const Spinner = ({ color = '#665AF5' }: IProps) => {
  return (
    <div className="spinner_container">
      <div className="spinner_content" style={{ color }}>
        <div className="load" style={{ borderLeft: `1px solid ${color}` }} />
        <div className="load2" style={{ borderLeft: `1px solid ${color}` }} />
        <p style={{ color }}>Cargando...</p>
      </div>
    </div>
  );
};

export default Spinner;
