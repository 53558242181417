import { Dialog, DialogContent, DialogTitle } from '@mui/material';

const ModalPayNegocios = ({ isOpen, handleClose }: any) => {
  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClick={handleClose}>
      <DialogTitle>Métodos de pago</DialogTitle>
      <DialogContent>
        <img
          src="https://napfood.s3.us-east-2.amazonaws.com/files/METODOS_PAGOS.jpg"
          style={{ width: '100%', borderRadius: '1rem' }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ModalPayNegocios;
