import { Routes, Route } from 'react-router-dom';

// Rutas de la aplicación
import routes from './config';
import { useNapNegocios } from 'context';
import Page401 from 'pages/Errors/Page401';

const ConfigRoutes = () => {
  const { userInfo, company } = useNapNegocios();

  // if (!userInfo._id || !company._id) return <></>;

  return (
    <Routes>
      {routes.map((e, ind) => {
        const { path, element: Component, children, isPrivate } = e;

        return isPrivate ? (
          <Route path={path} element={<Component />} key={ind}>
            {children?.map((el: any, indx: any) => {
              const { path: ruta, element: Element, roles: fixedRoles } = el;
              if (userInfo?.roles?.includes('superadmin'))
                return <Route path={ruta} element={<Element />} key={indx} />;

              const allowPaths = company?.plan?.paths || [];
              allowPaths.push('/');

              if (company.plan && !allowPaths?.find((item: any) => item === el.sidebarPath))
                return <Route path={ruta} element={<Page401 />} key={indx} />;

              const findRole = fixedRoles.find((role: string) => userInfo?.roles?.includes(role));
              if (!findRole) return <Route path={ruta} element={<Page401 />} key={indx} />; // Si no tiene el rol retorna 401

              if (el.onlyPrincipal === true && !company.isPrincipal)
                return <Route path={ruta} element={<Page401 />} key={indx} />; // Si el modulo es solo para la sede principal y la sede actual no es la principal retorna 401

              if (el.onlyPrincipal === false && company.isPrincipal)
                return <Route path={ruta} element={<Page401 />} key={indx} />; // Si el modulo es solo para la sede hija y la sede actual es la principal retorna 401

              return <Route path={ruta} element={<Element />} key={indx} />;
            })}
          </Route>
        ) : children ? (
          <Route path={path} element={<Component />} key={ind}>
            {children.map((el: any, indx) => {
              const { path: ruta, element: Element } = el;
              return <Route path={ruta} element={<Element />} key={indx} />;
            })}
          </Route>
        ) : (
          <Route path={path} element={<Component />} key={ind} />
        );
      })}
    </Routes>
  );
};

export default ConfigRoutes;
