// ! SOLO SEDES -> ONLYPRINCIPAL: false
// ! SOLO COMPAÑÍAS -> ONLYPRINCIPAL: true
// ! AMBAS -> ONLYPRINCIPAL: null

import { lazy } from 'react';
import Loadable from './Loadable';
// ----------------------------------------------------------------------
const Page404 = Loadable(lazy(() => import('pages/Errors/Page404')));
// ----------------------------------------------------------------------
const DashboardLayout = Loadable(lazy(() => import('layouts/dashboard')));
// const FreeLayout = Loadable(lazy(() => import('layouts/FreeLayout')));
// ----------------------------------------------------------------------
const Login = Loadable(lazy(() => import('pages/Login')));
//  ----------------------------------------------------------------------
const Dashboard = Loadable(lazy(() => import('pages/Dashboard')));
const Companies = Loadable(lazy(() => import('pages/Companies')));
// <====================== VISTA SOLO PRINCIPAL ======================>

// Productos
const Products = Loadable(lazy(() => import('pages/Products')));
const Promotions = Loadable(lazy(() => import('pages/Products/Promotions')));
const TypeGarment = Loadable(lazy(() => import('pages/Products/TypeGarment')));
const ProductUnits = Loadable(lazy(() => import('pages/Products/components/ProductUnits')));
const ProductTypes = Loadable(lazy(() => import('pages/Products/components/Types')));
const ProductPrices = Loadable(lazy(() => import('pages/Products/components/ProductPrices')));
const ProductChangeTypes = Loadable(lazy(() => import('pages/Products/ChangeTypes')));
const ProductsConversions = Loadable(lazy(() => import('pages/Products/Conversions')));
const Prices = Loadable(lazy(() => import('pages/Products/Prices')));
const ConfigProducts = Loadable(lazy(() => import('pages/Products/ConfigProducts')));
const ProductPriceStock = Loadable(lazy(() => import('pages/Products/PricesStock')));
const ProductChangeCategory = Loadable(lazy(() => import('pages/Products/ChangeCategory')));

// Sedes
const Headquarters = Loadable(lazy(() => import('pages/Headquarters')));

// Traslados
const AddStock = Loadable(lazy(() => import('pages/Transfers/Stock')));
const RequestStock = Loadable(lazy(() => import('pages/Transfers/RequestStock')));

// <====================== VISTA SEDES Y PRINCIPAL ======================>
const MyProducts = Loadable(lazy(() => import('pages/MyProducts')));
const MyConversions = Loadable(lazy(() => import('pages/MyConversions')));
const MyPrices = Loadable(lazy(() => import('pages/MyPrices')));
const MyConfigProducts = Loadable(lazy(() => import('pages/MyProducts/PricesStock')));

// Config
const CashRegisterConfig = Loadable(lazy(() => import('pages/Config/CashRegister')));
const MethodPaymentConfig = Loadable(lazy(() => import('pages/Config/MethodPayment')));
const PrinterConfig = Loadable(lazy(() => import('pages/Config/Printer')));
const OthersConfig = Loadable(lazy(() => import('pages/Config/Others')));

// Personas
const Providers = Loadable(lazy(() => import('pages/Persons/Providers')));
const Customers = Loadable(lazy(() => import('pages/Persons/Customers')));
const Users = Loadable(lazy(() => import('pages/Persons/Users')));
const Agencies = Loadable(lazy(() => import('pages/Persons/Agencies')));

//  Planes
const Planes = Loadable(lazy(() => import('pages/Planes/Planes')));

// Metricas
const Metrics = Loadable(lazy(() => import('pages/Metrics')));
const MetricsActividadRestaurantes = Loadable(lazy(() => import('pages/Metrics/views/CompanyActivity')));
const MetricsPendingVouchers = Loadable(lazy(() => import('pages/Metrics/views/PendingVouchers')));
const MetricsCerficateSunat = Loadable(lazy(() => import('pages/Metrics/views/CertificateSunat')));

// Reportes
const Reports = Loadable(lazy(() => import('pages/Reports')));
const ReportAccounting = Loadable(lazy(() => import('pages/Reports/Accounting')));
const ReportProformas = Loadable(lazy(() => import('pages/Reports/Proformas')));
const ReportClients = Loadable(lazy(() => import('pages/Reports/Clients')));
const ReportOrders = Loadable(lazy(() => import('pages/Reports/Orders')));
const ReportInvoices = Loadable(lazy(() => import('pages/Reports/Invoices')));
const ReportTickets = Loadable(lazy(() => import('pages/Reports/Tickets')));
const ReportProducts = Loadable(lazy(() => import('pages/Reports/Sales')));
const CanceledProducts = Loadable(lazy(() => import('pages/Reports/CanceledProducts')));
const ReportProductsSold = Loadable(lazy(() => import('pages/Reports/Products')));
const ReportClientProductsSold = Loadable(lazy(() => import('pages/Reports/ClientProducts')));
const ReportProductsFreeSold = Loadable(lazy(() => import('pages/Reports/FreeProducts')));
const ReportSalesNotes = Loadable(lazy(() => import('pages/Reports/SalesNotes')));
const ReportPayments = Loadable(lazy(() => import('pages/Reports/Payments')));
const ReportManualOutputs = Loadable(lazy(() => import('pages/Reports/ManualOutputs')));
const ReportPaymentsAccounts = Loadable(lazy(() => import('pages/Reports/PaymentsAccounts')));
const ReportCreditNotes = Loadable(lazy(() => import('pages/Reports/CreditNotes')));
const ReportProduct = Loadable(lazy(() => import('pages/Reports/Product')));
const ReportKardex = Loadable(lazy(() => import('pages/Reports/Kardex')));
const ReportSalesWithSeller = Loadable(lazy(() => import('pages/Reports/SalesWithSeller')));
const ReportTransfers = Loadable(lazy(() => import('pages/Reports/Transfers')));
const ReportGenerateInvoices = Loadable(lazy(() => import('pages/Reports/GenerateInvoices')));
const ReportGeneral = Loadable(lazy(() => import('pages/Reports/GeneralReport')));

// SUNAT
const Pending = Loadable(lazy(() => import('pages/Sunat/Pending')));
const Comprobants = Loadable(lazy(() => import('pages/Sunat/Comprobants')));
const Generate = Loadable(lazy(() => import('pages/Sunat/Generate')));
const GenerateVoucher = Loadable(lazy(() => import('pages/Sunat/GenerateVoucher')));
const Massives = Loadable(lazy(() => import('pages/Sunat/Massives')));

// Inventario
const Warehouse = Loadable(lazy(() => import('pages/Warehouse')));
const StockHistory = Loadable(lazy(() => import('pages/StockHistory')));
const GenerateTickets = Loadable(lazy(() => import('pages/GenerateTickets')));
const InventoryControl = Loadable(lazy(() => import('pages/InventoryControl')));

// Movimiento Diario
const DailyMovement = Loadable(lazy(() => import('pages/DailyMovement')));

// Importaciones
const Imports = Loadable(lazy(() => import('pages/Import')));

// Cuadre de Caja
const CashSettlement = Loadable(lazy(() => import('pages/CashSettlement/CashSettlement')));

// Ventas
const Sales = Loadable(lazy(() => import('pages/Sales')));

// Pagos Pendientes
const PendingPayments = Loadable(lazy(() => import('pages/PendingPayments')));

// Caja
const Cash = Loadable(lazy(() => import('pages/Cash')));

// Pagos a cuenta
const PaymentsAccount = Loadable(lazy(() => import('pages/PaymentsAccount')));

// Cotización
// const Quotation = Loadable(lazy(() => import('pages/Quotation')));

// Proformas
const Proformas = Loadable(lazy(() => import('pages/Proformas')));

// Guia Remisión
const GuiaRemision = Loadable(lazy(() => import('pages/GuiaRemision')));

// Kardex
const Kardex = Loadable(lazy(() => import('pages/Kardex')));

// Ingreso Almacén
const ShoppingRecord = Loadable(lazy(() => import('pages/ShoppingRecord')));

// Notifications
const Notifications = Loadable(lazy(() => import('pages/Notifications')));

// Ordenes (Delivery)
const Orders = Loadable(lazy(() => import('pages/Orders')));
const OrdersCalendar = Loadable(lazy(() => import('pages/Orders/components/OrdersCalendar')));

// Opticians
const Histories = Loadable(lazy(() => import('pages/Opticians/Histories')));
const Prescription = Loadable(lazy(() => import('pages/Opticians/Prescriptions/ViewPrescription')));
const PrescriptionsPanel = Loadable(lazy(() => import('pages/Opticians/Prescriptions')));

// Bingos
const Bingo = Loadable(lazy(() => import('pages/Bingo/Search')));
const BingoReport = Loadable(lazy(() => import('pages/Bingo/Report')));
const BingoImport = Loadable(lazy(() => import('pages/Bingo/Import')));
const BingoAdmin = Loadable(lazy(() => import('pages/Bingo/Admin')));
const BingoTypes = Loadable(lazy(() => import('pages/Bingo/Types')));

// Reservaciones
const Reservations = Loadable(lazy(() => import('pages/Reservations/Reservations')));
const CalendarReservation = Loadable(lazy(() => import('pages/Reservations/CalendarReservation')));

// Perfil
const Profile = Loadable(lazy(() => import('pages/Profile')));

// Recuperar Contraseña
const ResetWithToken = Loadable(lazy(() => import('pages/Errors/ResetWithToken')));
const ForgotPassword = Loadable(lazy(() => import('pages/Errors/ForgotPwd')));
// <====================================================================>

const routes = [
  {
    path: '/',
    roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'doctor', 'caja'],
    element: DashboardLayout,
    isPrivate: true,
    children: [
      {
        path: '',
        element: Dashboard,
        roles: ['superadmin', 'admin'],
        onlyPrincipal: null,
        sidebarPath: '/',
      },
      {
        path: 'reservations',
        element: Reservations,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
        onlyPrincipal: null,
        sidebarPath: '/reservations',
      },
      {
        path: 'reservations/calendar',
        element: CalendarReservation,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
        onlyPrincipal: null,
        sidebarPath: '/reservations/calendar',
      },
      {
        path: 'sunat/massive',
        element: Massives,
        roles: ['superadmin', 'admin', 'coordinador', 'contador'],
        onlyPrincipal: null,
        sidebarPath: '/sunat/massive',
      },
      {
        path: 'inventory/generate-tickets',
        element: GenerateTickets,
        roles: ['superadmin', 'admin', 'almacen'],
        onlyPrincipal: null,
        sidebarPath: '/inventory/generate-tickets',
      },
      {
        path: 'inventory/control',
        element: InventoryControl,
        roles: ['superadmin', 'admin', 'almacen', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/inventory/control',
      },
      {
        path: 'stock-history',
        element: StockHistory,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: null,
        sidebarPath: '/stock-history',
      },
      {
        path: 'warehouse',
        element: Warehouse,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'almacen', 'cajaSimple', 'cajaSinCuadre', 'vendedor'],
        onlyPrincipal: null,
        sidebarPath: '/warehouse',
      },
      {
        path: 'companies',
        element: Companies,
        roles: ['superadmin'],
        onlyPrincipal: true,
        sidebardPath: '/companies',
      },
      {
        path: 'products',
        element: Products,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: true,
        sidebarPath: '/products',
      },
      {
        path: 'promotions',
        element: Promotions,
        roles: ['superadmin'],
        onlyPrincipal: true,
        sidebarPath: '/promotions',
      },
      {
        path: 'products/type-garment',
        element: TypeGarment,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: true,
        sidebarPath: '/products/type-garment',
      },
      {
        path: 'products/config',
        element: ConfigProducts,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: true,
        sidebarPath: '/products/config',
      },
      {
        path: 'products/prices',
        element: Prices,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: true,
        sidebarPath: '/products/prices',
      },
      {
        path: 'products/price-stock',
        element: ProductPriceStock,
        roles: ['superadmin', 'admin', 'almacen', 'almacen'],
        onlyPrincipal: true,
        sidebarPath: '/products/price-stock',
      },
      {
        path: 'products/types',
        element: ProductTypes,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: true,
        sidebarPath: '/products/types',
      },
      {
        path: 'products/prices-types',
        element: ProductPrices,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: true,
        sidebarPath: '/products/prices-types',
      },
      {
        path: 'products/units',
        element: ProductUnits,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: true,
        sidebarPath: '/products/units',
      },
      {
        path: 'products/change-types',
        element: ProductChangeTypes,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: true,
        sidebarPath: '/products/change-types',
      },
      {
        path: 'products/conversions-general',
        element: ProductsConversions,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: true,
        sidebarPath: '/products/conversions-general',
      },
      {
        path: 'products/change-category',
        element: ProductChangeCategory,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: true,
        sidebarPath: '/products/change-category',
      },
      {
        path: 'my-products',
        element: MyProducts,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: null,
        sidebarPath: '/my-products',
      },
      {
        path: 'my-conversions',
        element: MyConversions,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/my-conversions',
      },
      {
        path: 'my-products/price-stock',
        element: MyConfigProducts,
        roles: ['superadmin', 'admin', 'importacion'],
        onlyPrincipal: null,
        sidebarPath: '/my-products/price-stock',
      },
      {
        path: 'my-prices',
        element: MyPrices,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/my-prices',
      },
      {
        path: 'cash-settlement',
        element: CashSettlement,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'cajaSimple'],
        onlyPrincipal: null,
        sidebarPath: '/cash-settlement',
      },
      {
        path: 'headquarters',
        element: Headquarters,
        roles: ['superadmin', 'admin'],
        onlyPrincipal: true,
        sidebarPath: '/headquarters',
      },
      {
        path: 'configuration/cash-register',
        element: CashRegisterConfig,
        roles: ['superadmin', 'admin'],
        onlyPrincipal: null,
        sidebarPath: '/configuration/cash-register',
      },
      {
        path: 'configuration/method-payment',
        element: MethodPaymentConfig,
        roles: ['superadmin', 'admin'],
        onlyPrincipal: null,
        sidebarPath: '/configuration/method-payment',
      },
      {
        path: 'configuration/printer',
        element: PrinterConfig,
        roles: ['superadmin', 'admin'],
        onlyPrincipal: null,
        sidebarPath: '/configuration/printer',
      },
      {
        path: 'configuration/others',
        element: OthersConfig,
        roles: ['superadmin', 'admin'],
        onlyPrincipal: null,
        sidebarPath: '/configuration/others',
      },
      {
        path: 'people/agencies',
        element: Agencies,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: true,
        sidebarPath: '/people/agencies',
      },
      {
        path: 'people/providers',
        element: Providers,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/people/providers',
      },
      {
        path: 'people/customers',
        element: Customers,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/people/customers',
      },
      {
        path: 'people/users',
        element: Users,
        roles: ['superadmin', 'admin'],
        onlyPrincipal: null,
        sidebarPath: '/people/users',
      },
      {
        path: 'proformas',
        element: Proformas,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
        onlyPrincipal: null,
        sidebarPath: '/proformas',
      },
      {
        path: 'guia-remision',
        element: GuiaRemision,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'contador', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/guia-remision',
      },
      {
        path: '/planes',
        element: Planes,
        roles: ['superadmin'],
        onlyPrincipal: null,
        sidebarPath: '/planes',
      },
      {
        path: 'reports',
        element: Reports,
        roles: ['superadmin', 'admin', 'coordinador', 'contador', 'caja', 'almacen', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/invoices',
        element: ReportInvoices,
        roles: ['superadmin', 'admin', 'contador', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/tickets',
        element: ReportTickets,
        roles: ['superadmin', 'admin', 'contador', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/accounting',
        element: ReportAccounting,
        roles: ['superadmin', 'admin', 'contador', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/proformas',
        element: ReportProformas,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/client',
        element: ReportClients,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/pedidos',
        element: ReportOrders,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/orders',
        element: ReportProducts,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/canceled-orders',
        element: CanceledProducts,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/products-sold',
        element: ReportProductsSold,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/products-client',
        element: ReportClientProductsSold,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/products-free-sold',
        element: ReportProductsFreeSold,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/sales-notes',
        element: ReportSalesNotes,
        roles: ['superadmin', 'admin', 'contador', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/payments',
        element: ReportPayments,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/manual-outputs',
        element: ReportManualOutputs,
        roles: ['superadmin', 'admin', 'contador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/payments-account',
        element: ReportPaymentsAccounts,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/credit-notes',
        element: ReportCreditNotes,
        roles: ['superadmin', 'admin', 'contador', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/kardex',
        element: ReportKardex,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/products',
        element: ReportProduct,
        roles: [
          'superadmin',
          'admin',
          'coordinador',
          'contador',
          'caja',
          'almacen',
          'cajaSimple',
          'cajaSinCuadre',
          'vendedor',
        ],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/sales-with-seller',
        element: ReportSalesWithSeller,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/transfers',
        element: ReportTransfers,
        roles: ['superadmin', 'admin', 'coordinador', 'almacen', 'caja', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/generate-invoices',
        element: ReportGenerateInvoices,
        roles: ['superadmin', 'admin', 'contador', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'reports/general',
        element: ReportGeneral,
        roles: ['superadmin', 'admin', 'contador', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/reports',
      },
      {
        path: 'sunat/pending',
        element: Pending,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'contador', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/sunat/pending',
      },

      {
        path: 'sunat/vouchers',
        element: Comprobants,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'contador', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/sunat/vouchers',
      },
      {
        path: 'sunat/company',
        element: Generate,
        roles: ['superadmin'],
        onlyPrincipal: null,
        sidebarPath: '/sunat/company',
      },
      {
        path: 'sunat/generate-voucher',
        element: GenerateVoucher,
        roles: ['superadmin', 'admin', 'caja', 'contador'],
        onlyPrincipal: null,
        sidebarPath: '/sunat/generate-voucher',
      },
      {
        path: 'daily-movement',
        element: DailyMovement,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'almacen'],
        onlyPrincipal: null,
        sidebarPath: '/daily-movement',
      },

      {
        path: 'imports',
        element: Imports,
        roles: ['superadmin', 'admin', 'importacion'],
        onlyPrincipal: null,
        sidebarPath: '/imports',
      },
      {
        path: 'metrics',
        element: Metrics,
        roles: ['superadmin', 'admin', 'coordinador', 'contador'],
        onlyPrincipal: null,
        sidebarPath: '/metrics',
      },
      {
        path: 'metrics/actividad-empresas',
        element: MetricsActividadRestaurantes,
        roles: ['superadmin', 'admin', 'coordinador', 'contador'],
        onlyPrincipal: null,
        sidebarPath: '/metrics',
      },
      {
        path: 'metrics/comprobantes-pendientes',
        element: MetricsPendingVouchers,
        roles: ['superadmin', 'admin', 'coordinador', 'contador'],
        onlyPrincipal: null,
        sidebarPath: '/metrics',
      },
      {
        path: 'metrics/certificados-sunat',
        element: MetricsCerficateSunat,
        roles: ['superadmin', 'admin', 'coordinador', 'contador'],
        onlyPrincipal: null,
        sidebarPath: '/metrics',
      },
      {
        path: 'sales',
        element: Sales,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/sales',
      },
      {
        path: 'pending-payments',
        element: PendingPayments,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/pending-payments',
      },
      {
        path: 'cash',
        element: Cash,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/cash',
      },
      {
        path: 'payments-account',
        element: PaymentsAccount,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/payments-account',
      },
      {
        path: 'kardex',
        element: Kardex,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'almacen'],
        onlyPrincipal: null,
        sidebarPath: '/kardex',
      },
      // {
      //   path: 'quotation',
      //   element: Quotation,
      //   roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
      //   onlyPrincipal: null,
      //   sidebarPath: '/quotation',
      // },
      {
        path: 'shopping-record',
        element: ShoppingRecord,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'almacen'],
        onlyPrincipal: null,
        sidebarPath: '/shopping-record',
      },
      {
        path: 'transfers/stock',
        element: AddStock,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'almacen', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/transfers/stock',
      },
      {
        path: 'transfers/stock-requests',
        element: RequestStock,
        roles: ['superadmin', 'admin', 'coordinador', 'caja', 'almacen', 'cajaSimple', 'cajaSinCuadre'],
        onlyPrincipal: null,
        sidebarPath: '/transfers/stock-requests',
      },
      {
        path: 'notifications',
        element: Notifications,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
        onlyPrincipal: null,
        sidebarPath: '/notifications',
      },
      {
        path: 'orders',
        element: Orders,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
        onlyPrincipal: null,
        sidebarPath: '/orders',
      },
      {
        path: 'orders/calendar',
        element: OrdersCalendar,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
        onlyPrincipal: null,
        sidebarPath: '/orders/calendar',
      },
      // Opticians
      {
        path: 'histories',
        element: Histories,
        roles: ['superadmin', 'admin', 'coordinador', 'doctor'],
        onlyPrincipal: null,
        sidebarPath: '/histories',
      },
      {
        path: 'histories/:prescriptionId',
        element: Prescription,
        roles: ['superadmin', 'admin', 'coordinador', 'doctor'],
        onlyPrincipal: null,
        sidebarPath: '/histories',
      },
      {
        path: 'prescriptions',
        element: PrescriptionsPanel,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/prescriptions',
      },
      {
        path: '/profile',
        element: Profile,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
        onlyPrincipal: null,
        sidebarPath: '/',
      },
      {
        path: 'bingo/search',
        element: Bingo,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
        onlyPrincipal: null,
        sidebarPath: '/bingo/search',
      },
      {
        path: 'bingo/reports',
        element: BingoReport,
        roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
        onlyPrincipal: null,
        sidebarPath: '/bingo/reports',
      },
      {
        path: 'bingo/import',
        element: BingoImport,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/bingo/import',
      },
      {
        path: 'bingo/admin',
        element: BingoAdmin,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/bingo/admin',
      },
      {
        path: 'bingo/types',
        element: BingoTypes,
        roles: ['superadmin', 'admin', 'coordinador'],
        onlyPrincipal: null,
        sidebarPath: '/bingo/admin',
      },
    ],
  },
  {
    path: '/forgotpassword',
    element: ForgotPassword,
    isPrivate: false,
    roles: ['superadmin', 'admin', 'coordinador'],
  },
  {
    path: '/forgotpassword/:token',
    element: ResetWithToken,
    isPrivate: false,
    roles: ['superadmin', 'admin', 'coordinador'],
  },
  {
    path: '/login',
    element: Login,
    isPrivate: false,
    roles: ['superadmin', 'admin', 'coordinador'],
  },
  {
    path: '*',
    element: Page404,
    isPrivate: false,
    children: [],
    roles: ['superadmin', 'admin', 'coordinador', 'vendedor', 'caja'],
  },
];

export default routes;
