import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNapNegocios } from 'context';
import { getProductPrices } from 'requests/products';
import { notification } from 'antd';
import { IPrice, IProduct } from 'interfaces/Product';

interface IPriceContext {
  load: boolean;
  data: IPrice[];
  reloadFunction: Function;
  getDataPriceById: (id: string) => IPrice | undefined;
  getActualPriceByUnit: (prd: any, id: string) => number;
  getActualPriceByUnitDefault: (prd: any) => number;
}

const PriceContext = createContext<IPriceContext>({} as any);
export const usePriceContext = () => useContext(PriceContext);

const PriceContextProvider = ({ children }: { children: ReactNode }) => {
  const { company } = useNapNegocios();
  const [data, setData] = useState<IPrice[]>([]);
  const [load, setLoading] = useState(false);

  useEffect(() => {
    if (company._id) {
      obtainData();
    }
  }, [company._id]);

  const obtainData = async () => {
    setLoading(true);
    try {
      const res = await getProductPrices(company._id);
      setData(res?.reverse());
    } catch (error) {
      notification.error({
        message: `Ocurrió un error al realizar la operación.`,
      });
    } finally {
      setLoading(false);
    }
  };

  const getActualPriceByUnit = (product: IProduct, id: string) => {
    const findPrice = product?.unitInfo?.precios?.find((el: any) => el.description === id);
    if (!findPrice) return 0;

    return findPrice.value;
  };

  const getActualPriceByUnitDefault = (product: IProduct) => {
    const findPrice = product.unidades.find((el: any) => el?._id === product.unitDefault);

    const pricePublicId = data.find((el) => el.name === 'PÚBLICO')!;
    const returnPrice = findPrice?.precios.find((el: any) => el.description === pricePublicId?._id);

    if (!findPrice) return 0;

    return returnPrice?.value ?? 0;
  };

  const getDataPriceById = (id: string) => {
    const find = data.find((el) => el._id === id);
    return find;
  };

  return (
    <PriceContext.Provider
      value={{
        load,
        data,
        reloadFunction: obtainData,
        getDataPriceById,
        getActualPriceByUnit,
        getActualPriceByUnitDefault,
      }}
    >
      {children}
    </PriceContext.Provider>
  );
};

export default PriceContextProvider;
