class StorageService {
  static set(key: any, data: any) {
    return localStorage.setItem(key, JSON.stringify(data));
  }

  static get(key: string) {
    return JSON.parse(localStorage.getItem(key) || 'null');
  }

  static remove(key: any) {
    return localStorage.removeItem(key);
  }
}

export default StorageService;
