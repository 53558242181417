import axios from 'axios';
import { API_REPORTES } from 'config/api.config';
import UserService from 'config/UserService';
import { IPrice, IProduct, ITypeProduct } from 'interfaces/Product';
import { ITypeGarment } from 'interfaces/TypeGarment';
import { NapNegociosAxios } from './config';

export const postProducts = async (data: IProduct, type: string) => {
  const res = await axios.post(`${API_REPORTES}/product/${type}/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const putProducts = async (data: IProduct, type: string, id: string) => {
  const res = await NapNegociosAxios.put(`/product/${type}/${id}`, data);
  return res.data;
};

export const deleteProducts = async (id: string) => {
  const res = await NapNegociosAxios.delete(`/product/${id}`);
  return res.data;
};

export const importProducts = async (data: Object, type: string) => {
  const res = await axios.post(`${API_REPORTES}/product/import/${type}/${UserService.campus()}`, data, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getProducts = async () => {
  const res = await axios.get(`${API_REPORTES}/product/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getProductsByCategory = async (category: string) => {
  const res = await axios.get(`${API_REPORTES}/category/products/${category}/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

interface IAssigmentProductsCategory {
  categoryIds: string[];
  freeIds: string[];
}
export const assignProductsCategory = async (category: string, data: IAssigmentProductsCategory) => {
  const res = await NapNegociosAxios.put(`/category/assign/${category}/${UserService.campus()}`, data);
  return res.data;
};

export const getKardex = async (product: string) => {
  const res = await axios.get(`${API_REPORTES}/operation/${product}/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

interface IAssigmentProducts {
  aggregates: any[];
}
export const assigmentProducts = async (data: IAssigmentProducts, product: string) => {
  const res = await NapNegociosAxios.put(`/product/aggregates/${product}`, data);
  return res.data;
};
export const assigmentProductsBySede = async (data: IAssigmentProducts, product: string) => {
  const res = await NapNegociosAxios.put(`/item/aggregates/${product}`, data);
  return res.data;
};

interface IUpdateStatus {
  product: string;
  sedes: {
    _id: string;
    status: boolean;
  }[];
}

export const updateStatusProducts = async (data: IUpdateStatus[]) => {
  const res = await NapNegociosAxios.put(`/item/status/${UserService.campus()}`, data);
  return res.data;
};

interface IUpdateCategoryStatus {
  category: string;
  sedes: {
    _id: string;
    status: boolean;
  }[];
}

export const updateStatusCategory = async (data: IUpdateCategoryStatus[]) => {
  const res = await NapNegociosAxios.put(`/item/status-category/${UserService.campus()}`, data);
  return res.data;
};

export const disableAllProducts = async () => {
  const res = await NapNegociosAxios.delete(`/item/disabled-all/${UserService.campus()}`);
  return res.data;
};

export const getStatusProduct = async (id: string) => {
  const res = await NapNegociosAxios.get(`/item/status/${id}/${UserService.campus()}`);
  return res.data;
};

/// TYPES

export const postProductType = async (data: ITypeProduct) => {
  const res = await NapNegociosAxios.post(`/category/${UserService.campus()}`, data);
  return res.data;
};

export const getProductType = async () => {
  const res = await NapNegociosAxios.get(`/category/${UserService.campus()}`);
  return res.data;
};

export const deleteProductType = async (id: string) => {
  try {
    const res = await NapNegociosAxios.delete(`/category/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const putProductType = async (id: string, data: ITypeProduct) => {
  const res = await NapNegociosAxios.put(`/category/${id}`, data);
  return res.data;
};

// PRICES
export const getProductPrices = async (id: string) => {
  const res = await NapNegociosAxios.get(`/price/${id}`);
  return res.data;
};

export const postProductPrices = async (id: string, data: IPrice) => {
  const res = await NapNegociosAxios.post(`/price/${id}`, data);
  return res.data;
};

export const putProductPrices = async (id: string, data: IPrice) => {
  const res = await NapNegociosAxios.put(`/price/${id}`, data);
  return res.data;
};

export const deleteProductPrices = async (id: string) => {
  const res = await NapNegociosAxios.delete(`/price/${id}`);
  return res.data;
};

export const updatePricesByUnit = async (data: any) => {
  const res = await NapNegociosAxios.put(`/product/update-prices`, data);
  return res.data;
};

export const updatePricesLiquidation = async (data: any) => {
  const res = await NapNegociosAxios.put(`/product/update-liquidation/${UserService.company()}`, data);
  return res.data;
};

// MOVIMIENTO DIARIO

export const getDailyMovement = async () => {
  const res = await NapNegociosAxios.get(`/product/inventory/${UserService.campus()}`);
  return res.data;
};

interface IPostDailyMovement {
  products: {
    _id: string;
    output: number;
    stock: number;
    finalStock: number;
  }[];
  idsOrders: string[];
}
export const postDailyMovement = async (data: IPostDailyMovement) => {
  const res = await NapNegociosAxios.post(`/operation/massive/${UserService.campus()}`, data);
  return res.data;
};

export const getLengthDailyMovement = async () => {
  const res = await NapNegociosAxios.get(`/item/count-inventory/${UserService.campus()}`);
  return res.data;
};

// By Sedes
export const getProductsBySede = async () => {
  const res = await axios.get(`${API_REPORTES}/item/${UserService.campus()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};
// Productos para vender por sede
export const getSaleProductsBySede = async () => {
  const res = await NapNegociosAxios.get(`/item/sale/${UserService.campus()}`);
  return res.data;
};

export const putPricesBySede = async (data: any, id: string) => {
  const res = await NapNegociosAxios.put(`/item/${id}`, data);
  return res.data;
};

export const changeStockInItem = async (itemId: string, data: any) => {
  const res = await NapNegociosAxios.put(`/item/change-stock/${itemId}`, data);
  return res.data;
};

export const putStockAndPricesBySede = async (data: any) => {
  const res = await NapNegociosAxios.put(`/item/update-stock-prices/${UserService.campus()}`, data);
  return res.data;
};

export const getProductsByCodeWarehouse = async (code: string) => {
  const res = await axios.get(`${API_REPORTES}/item/warehouse/${code}/${UserService.company()}`, {
    headers: { Authorization: UserService.token() },
  });
  return res.data;
};

export const getTypeGarment = async () => {
  const res = await NapNegociosAxios.get<ITypeGarment[]>(`/type-garment/${UserService.company()}`);
  return res.data;
};

export const postTypeGarment = async (data: any) => {
  const res = await NapNegociosAxios.post(`/type-garment/${UserService.company()}`, data);
  return res.data;
};

export const putTypeGarment = async (id: string, data: any) => {
  const res = await NapNegociosAxios.put(`/type-garment/${id}`, data);
  return res.data;
};

export const deleteTypeGarment = async (id: string) => {
  const res = await NapNegociosAxios.delete(`/type-garment/${id}`);
  return res.data;
};
