import ConfigRoutes from 'routes';
import ThemeConfig from 'theme';
import GlobalStyles from 'theme/globalStyles';
import { BrowserRouter } from 'react-router-dom';
import NapNegociosProvider from 'context';
import OrderContextProvider from 'context/order';
import moment from 'moment-timezone';
import 'moment/locale/es';
import PriceContextProvider from 'context/price';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from 'date-fns/esm/locale/es';
import NotificationProvider from 'context/notification';
import LayoutContextProvider from 'context/layout';
import RequestStockProvider from 'context/requestStock';
// ----------------------------------------------------------------------

export default function App() {
  moment.tz.setDefault('America/Lima');

  return (
    <BrowserRouter>
      <NapNegociosProvider>
        <ThemeConfig>
          <OrderContextProvider>
            <PriceContextProvider>
              <NotificationProvider>
                <RequestStockProvider>
                  <LayoutContextProvider>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
                      <GlobalStyles />
                      <ConfigRoutes />
                    </LocalizationProvider>
                  </LayoutContextProvider>
                </RequestStockProvider>
              </NotificationProvider>
            </PriceContextProvider>
          </OrderContextProvider>
        </ThemeConfig>
      </NapNegociosProvider>
    </BrowserRouter>
  );
}
