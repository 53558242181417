import PropTypes from 'prop-types';
import PerfectScrollBar from 'react-perfect-scrollbar';
// material
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ReactNode, CSSProperties } from 'react';

// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  flexGrow: 1,
  height: '100%',
  overflow: 'hidden',
});

// ----------------------------------------------------------------------

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
};

interface IProps {
  children: ReactNode;
  sx: CSSProperties;
}

export default function Scrollbar({ children, sx, ...other }: IProps) {
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <RootStyle>
      <PerfectScrollBar style={sx} {...other}>
        {children}
      </PerfectScrollBar>
    </RootStyle>
  );
}
