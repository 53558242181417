import { useNapNegocios } from 'context';
import { IProductSede } from 'interfaces/Product';
import { createContext, ReactNode, useContext, useState } from 'react';

interface IContext {
  setModalPrintBluetooth: Function;
  modalPrintBluetooth: any;
  resetOrder: Function;
  fields: any[];
  setFields: Function;
  directPay: any;
  setDirectPay: Function;
  orderInfo: any;
  setOrderInfo: Function;
  allProductsSede: IProductSede[];
  setAllProductsSede: Function;
  clearOrder: () => void;
}

export const OrderContext = createContext({} as IContext);
export const useOrderContext = () => useContext(OrderContext);

const OrderContextProvider = ({ children }: { children: ReactNode }) => {
  const { company } = useNapNegocios();

  const [orderInfo, setOrderInfo] = useState({
    items: [],
    conceptos: [],
    customer: { _id: '' },
    discount: '0',
    prescription: { _id: '', name: '', customer: '' },
  });
  const [fields, setFields] = useState([]);
  const [directPay, setDirectPay] = useState(null); // Cuando le das a finalizar venta te manda directamente a pagar
  const [modalPrintBluetooth, setModalPrintBluetooth] = useState({
    isOpen: false,
    data: null as any,
  });
  const [allProductsSede, setAllProductsSede] = useState([]);

  const resetOrder = () => {
    if (company.reloadItemsAfterOrder) setFields([]);
    setOrderInfo({
      items: [],
      conceptos: [],
      customer: { _id: '' },
      discount: '0',
      prescription: { _id: '', name: '', customer: '' },
    });
  };

  const clearOrder = () => {
    setOrderInfo({
      items: [],
      conceptos: [],
      customer: { ...orderInfo.customer },
      discount: '0',
      prescription: { ...orderInfo.prescription },
    });
  };

  return (
    <OrderContext.Provider
      value={{
        setModalPrintBluetooth,
        modalPrintBluetooth,
        resetOrder,
        fields,
        setFields,
        directPay,
        setDirectPay,
        orderInfo,
        setOrderInfo,
        allProductsSede,
        setAllProductsSede,
        clearOrder,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};
export default OrderContextProvider;
