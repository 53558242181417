import { Helmet } from 'react-helmet-async';
import { ReactElement, ReactNode, useState } from 'react';
// material
import { Alert, Box, Button, Slide } from '@mui/material';
import Scrollbar from './Scrollbar';
import { useLayoutContext } from 'context/layout';
import { fCurrency } from 'utils/formatNumber';
import moment from 'moment-timezone';
import ModalPayNegocios from './ModalPayNegocios';

// ----------------------------------------------------------------------
const HEIGHT = 'calc(100vh - 5px)';

type IDirection = 'up' | 'down' | 'left' | 'right';
const types: IDirection[] = ['down', 'left', 'right', 'up'];

interface IProps {
  children: ReactNode;
  title?: string;
}

/**
 * Componente para renderizar páginas
 *
 * @param children - Contenido de la página
 * @param title - Título de la página (Opcional)
 * @return ReactElement
 */

const Page = ({ children, title = '', ...other }: IProps): ReactElement => {
  const { infoPayment } = useLayoutContext();

  const [modal, setModal] = useState({ type: '' as any });

  return (
    <Box {...other}>
      <Helmet>
        <title>{title} | NapNegocios</title>
      </Helmet>
      <Scrollbar sx={{ height: HEIGHT }}>
        <Slide in={true} timeout={500} appear direction={types[Math.floor(Math.random() * 4)]}>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.up('lg')]: {
                paddingTop: '95px',
              },
              paddingTop: '45px',
            })}
          >
            {infoPayment?.isActive && (
              <>
                <Box sx={{ mb: 1 }}>
                  <Alert severity="error" sx={{ textAlign: 'center' }}>
                    Tiene {infoPayment.quantity} pago(s) pendiente{' '}
                    {infoPayment.amount > 0 ? 'por un monto total de' : ''}{' '}
                    <b>{infoPayment.amount > 0 ? fCurrency(infoPayment.amount) : ''}</b> en concepto de{' '}
                    <b>{infoPayment.description}</b>. La fecha de corte:{' '}
                    <b> {moment(infoPayment.dueDate).format('DD/MM/YYYY HH:mm a')}.</b>
                    &nbsp;&nbsp;&nbsp;
                    <Button size="small" color="error" variant="outlined" onClick={() => setModal({ type: 'DEBTS' })}>
                      Ver métodos de pago
                    </Button>
                    {/* <a href={linkToPay} target="_blank" rel="noreferrer">
                  <Button size="small" color="success" variant="outlined">
                    Pagar ahora
                  </Button>
                </a> */}
                  </Alert>
                </Box>
                {modal.type === 'DEBTS' && (
                  <ModalPayNegocios isOpen={modal.type === 'DEBTS'} handleClose={() => setModal({ type: null })} />
                )}
              </>
            )}
            <main style={{ height: '100%', position: 'relative' }}>{children}</main>
          </Box>
        </Slide>
      </Scrollbar>
    </Box>
  );
};

export default Page;
