import axios from 'axios';
import { API_NAPNEGOCIOS, API_REPORTES } from 'config/api.config';
import UserService from 'config/UserService';

export const getNotifications = async (userId: string) => {
  const res = await axios.get(`${API_REPORTES}/notification/${userId}/${UserService.campus()}`, {
    headers: {
      Authorization: `${UserService.token()}`,
    },
  });

  return res.data;
};

interface INotificationSend {
  user: string;
  title: string;
  message: string;
  sedes: string[];
}

export const postNotification = async (notification: INotificationSend) => {
  const res = await axios.post(`${API_NAPNEGOCIOS}/notification/${UserService.campus()}`, notification, {
    headers: {
      Authorization: `${UserService.token()}`,
    },
  });

  return res.data;
};

export const sendReadNotification = async (userId: string, notificationId: string) => {
  // Peticion para marcar como leido
  const res = await axios.get(`${API_NAPNEGOCIOS}/notification/view/${userId}/${notificationId}`, {
    headers: {
      Authorization: `${UserService.token()}`,
    },
  });

  return res.data;
};
