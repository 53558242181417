import axios from 'axios';
import { API_NAPNEGOCIOS, API_REPORTES } from 'config/api.config';
import UserService from 'config/UserService';
import { ITransferStock } from 'interfaces/Transfer';

export const postTransferStock = async (data: ITransferStock) => {
  const res = await axios.post(`${API_NAPNEGOCIOS}/request-kardex/${UserService.campus()}`, data, {
    headers: {
      Authorization: ` ${UserService.token()}`,
    },
  });

  return res.data;
};

export const getTransferStock = async () => {
  const res = await axios.get(`${API_REPORTES}/request-kardex/${UserService.campus()}`, {
    headers: {
      Authorization: ` ${UserService.token()}`,
    },
  });

  return res.data;
};

export const putTransferStock = async (data: ITransferStock, id: string) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/request-kardex/${id}`, data, {
    headers: {
      Authorization: ` ${UserService.token()}`,
    },
  });

  return res.data;
};

export const deleteTransferStock = async (id: string) => {
  const res = await axios.delete(`${API_NAPNEGOCIOS}/request-kardex/${id}`, {
    headers: {
      Authorization: ` ${UserService.token()}`,
    },
  });

  return res.data;
};

export const acceptTransfer = async (idTransfer: string) => {
  const res = await axios.put(
    `${API_NAPNEGOCIOS}/request-kardex/accept/${idTransfer}/${UserService.campus()}`,
    {},
    {
      headers: {
        Authorization: ` ${UserService.token()}`,
      },
    }
  );

  return res.data;
};

export const sendTransfer = async (
  idTransfer: string,
  data: { information: { user: string; phone: string; date: Date | string; comment: string } }
) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/request-kardex/send/${idTransfer}/${UserService.campus()}`, data, {
    headers: {
      Authorization: ` ${UserService.token()}`,
    },
  });

  return res.data;
};

export const receiveTransfer = async (
  idTransfer: string,
  data: { information: { user: string; phone: string; date: Date | string; comment: string } }
) => {
  const res = await axios.put(`${API_NAPNEGOCIOS}/request-kardex/receive/${idTransfer}/${UserService.campus()}`, data, {
    headers: {
      Authorization: ` ${UserService.token()}`,
    },
  });

  return res.data;
};

export const postCommentTransfer = async (id: string, data: { user: string; message: string }) => {
  const res = await axios.post(`${API_NAPNEGOCIOS}/request-kardex/message/${id}`, data, {
    headers: {
      Authorization: ` ${UserService.token()}`,
    },
  });

  return res.data;
};
