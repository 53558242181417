import axios from 'axios';
import { API_NAPNEGOCIOS } from 'config/api.config';
import UserService from 'config/UserService';

interface IAuthUser {
  username: string;
  password: string;
}
export const authUser = async (data: IAuthUser) => {
  const res = await axios.post(`${API_NAPNEGOCIOS}/auth/signin`, data);
  return res.data;
};

export const getUserInfo = async () => {
  if (UserService.user() && UserService.campus()) {
    const res = await axios.get(
      `${API_NAPNEGOCIOS}/auth/validToken/${UserService.user()}/${UserService.campus()}`,
      { headers: { Authorization: UserService.token() } }
    );
    return res.data;
  } else {
    return null;
  }
};

export const dashboardInfo = async (day: string, month: string, year: string) => {
  const res = await axios.get(
    `${API_NAPNEGOCIOS}/dashboard/${UserService.campus()}/${day}/${month}/${year}`,
    { headers: { Authorization: UserService.token() } }
  );
  return res.data;
};
